<template>
  <div></div>
</template>

<script>


import eventLogger from '../../services/eventLogger';


import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "Welcome",

  data() {
    return {
      insuranceTypes: [],
      insuranceTypeLoading: true,
      banner: null,
      bannerLoading: true,
      options: {
        "arrows": false,
        "dots": false,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 700,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "lazyLoad": "ondemand",
        "autoplay": true,
        "autoplaySpeed": 10000,
        // "touchMove": false,
        "draggable": false,
      },

    }
  },
  // created(){
  //   this.$loading.show();
  //   document.onreadystatechange = () => { //To load the app only after all libraries are loaded
  //     if (document.readyState == "complete") {
  //       this.$loading.hide();
  //
  //     }
  //   }
  //
  // },
  mounted() {

    this.authLink()

  },
  methods: {
    async getInsuranceTypes() {
      try {
        const {data} = await window.axios.get('insurance-types/main');
        this.insuranceTypes = data;
        this.insuranceTypeLoading = false;
      } catch (e) {
        throw e;
      }
    },
    authLink() {
      let route =  this.$router.resolve({name: 'Welcome'});
      let appUrl = this.$store.getters.appUrl + route.href;
      if(this.$store.getters.isNewVersionHB){
        location.replace(`${this.$store.getters.hbUrl}${appUrl}`);
      }else
        location.replace(route.href)
      },

    async getBanner() {
      try {
        const {data} = await window.axios.get('main-page-banners');
        this.banner = data;
        this.bannerLoading = false;
      } catch (e) {
        throw e;
      }
    },
    mainBannerClicked(url) {
      eventLogger('select_main_banner');
      // alert(url);
      location.href = url;
    },

    productsClicked(productTypeId) {
      let productName;

      switch(productTypeId) {
        case 1:
          productName = 'select_car_insurance';
          break;
        case 2:
          productName = 'select_home_insurance'
          break;
        case 3:
          productName = 'select_health_insurance'
          break;
        case 4:
          productName = 'select_travel_insurance'
          break;
        case 5:
          productName = 'select_fin_insurance'
          break;
        default:
          productName = 'undefined'
          break;
      }

      eventLogger(productName);
    }
  }
}
</script>

<style lang="scss">
@import "../../sass/variables";
.banner-loading {
  min-height: 50vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.banner {
  height: auto;
  margin-bottom: 16px;
  background-size: cover !important;
  background-position-y: bottom !important;
  width: 100vw !important;
  max-width: 768px;

  .banner__container {
    padding: 16px;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    min-height: 50vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .left-side {
      width: 180px;
    }
    .title {
      color: #fff;
      font-size: 18px;
      font-weight: 800;
      line-height: 20px;
    }

    .desc {
      color: #fff;
      font-size: 14px;
      font-weight: 800;
    }

    .btn {
      color: #222;
      font-size: 16px;
    }
  }
}

.welcome-page {
  .insurance-types {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 0 16px;

    .insurance-types__item {
      background: #FFFFFF;
      box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 8px;
      &__container {
        max-width: 180px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;
      }
      .img {
        align-self: flex-end;
        max-width: 100px;
        height: 75px;
        object-fit: contain;
      }
      .text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
      }
    }
  }
}

@media (max-width: 768px) {
  .welcome-page {
    .insurance-types {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .banner {
    .banner__container {
      min-height: 40vh;
    }
  }
  .banner-loading {
    min-height: 40vh;
  }
}
@media (max-width: 555px) {
  .welcome-page {
    .insurance-types {
      grid-template-columns: repeat(2, 1fr);
      .insurance-types__item  {
        img {
          width: 80%;
          height: 60px;
        }
      }
    }
  }
  .banner {
    .banner__container {
      min-height: 30vh;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 30vh;
  }
}
@media (max-width: 430px) {
  .banner {
    .banner__container {
      min-height: 200px;
      max-height: 200px;
      .left-side {
        width: 120px;
      }
    }
  }
  .banner-loading {
    min-height: 200px;
    max-height: 200px;
  }
}
</style>
